html{
    font-size: 1vw;
}

body {
    font-family: "Roboto", "Arial", "sans-serif";
    padding: 0;
    margin: 0;
    height: 100vh;
    fill: white;
}

.content{
    background-color: rgb(241, 244, 246);
    padding-top: 3.5rem;
}

.logo{
    width: 5em;
    height: 5em;
}

.plain{
    text-decoration: none;
}

.iconButton{
    width:1em;
    height:1em;
}

.dashButton{
    font-size:1em;
}

.copyButton{
    margin-left:0.25em;
}

.copyButton:hover {
    color:gray;
}

.wolf{
min-width:1.5em;
min-height:1.5em;
margin-right:0.5em;
display:inline-block;
background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='97.12'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath d='M0 0h100v97.12H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Artboard – 1' clip-path='url(%23a)'%3E%3Cpath d='M47.215 32.287c6.516-2.064 7.073-7.793 8.967-12.719 1.546-4.021 2.069-4.261 5.542-1.712 2.6 1.908 3.366 3.424 3.087 6.462-.2 2.147-.289 5.024-.289 7.269 2.617-7.744 4.672-7.269 4.672-7.269 1.091-.1 2.558 2.1 2.558 2.1 1.154 1.644.24 4.588-.269 5.64 0 0-3.742 9.95-4.5 15.654 0 0-.841 3.5-1.086 5.283a79.866 79.866 0 00-1.174 11.936 30.468 30.468 0 001.076 10.76c.489 1.991 3.351 11.148.925 21.431a50 50 0 10-50.755-10.488c.386-.773.758-1.556 1.106-2.368a45.131 45.131 0 015.1-8.316c2.446-3.424 5.1-6.677 7.675-10.013-.044-.054-.083-.161-.161-.2-5.826-3.052-6.178-3.772-1.022-7.807s8.937-8.624 10.84-15.14c1.223-5.327 3.331-9.148 7.7-10.507'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
}

.expandedIcon{
}

.heading{
    font-size:2em;
}