.contactTableWrapper{
    padding-bottom:2em;
}

.contactTableHeader{
    text-align: center;
}

.contactTable{
    font-size:0.8em;
	width:100%;
			border-radius:0.5em;
		overflow:hidden;
		        box-shadow:0 4px 8px 0 rgba(0,0,0,0.2);
		        background-color:white;
        box-sizing: border;

}

.contactCellHeader {
    text-align: center;
    padding:0.5em;
    white-space: nowrap;
}

.contactCell {
    text-align: center;
}


.contactCellContent {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactCellValue{
    white-space: nowrap;
    display:block;
}

.contactGroup{ 
}