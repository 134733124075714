.contactBox{
    width:50%;
    background-color: white;
    padding:2em;
    border-radius: 1em;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:1.2em;
}
.contactInner{
    width:80%;
}
.roleSelect{
    height:1.8em;
}
.contactInquiry{
    resize:none;
    width:100%;
}
.contactButton{
    font-size:1.2em;
}