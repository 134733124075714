.navheader{
	height: 3.5rem;
}

.iconLarge{
	width:1.5em;
	height:1.5em;
}
.navRow{
	display:flex;
}
.navButton{
	text-decoration:none;
	color:white;
	padding: 1rem;
	font-size:1.2rem;
	height:3.5rem !important;
	display:flex;
	align-items: center;
	white-space: nowrap;
}

.navButton:hover{
color:#0d6efd;
}

.navButton.active{
	color:#0d6efd;
}