.dataContent{
	width: 100%;
	height: 100%;
	background-color: rgb(241, 244, 246);
    scrollbar-gutter: stable both-edges;
	padding: 1em;
	width: 100%;
	height: 100%;
}

.contentCentered{
	width: 100%;
	height: 100%;
	display:flex;
	justify-content:center;
	align-items:center;
}


.dataTableWrapper{
	width: 100%;
}
.dataTable{
	font-size:0.8em;
	width:100%;
			border-radius:0.5em;
		overflow:hidden;
		        box-shadow:0 4px 8px 0 rgba(0,0,0,0.2);
		        background-color:white;
        box-sizing: border;

}
.modifiedRow{
	background-color: #FFFD74;
}
.modifiedCell{
	background-color: #98FB98;
}
.dataRow{
}
.cellEditButton{
}

.dataCell{
	padding: 0.25em;
		text-align: center;
}
.dataCell:hover {
	border:1px solid transparent;
box-shadow: 0px 0px 10px lime;
    border-style:double;
    animation: cellGlow 800ms ease-out infinite alternate;
}
@keyframes cellGlow {
    0% {
box-shadow: 0px 0px 20px lime;
    }	
    100% {
box-shadow: 0px 0px 10px lime;
    }
}
.cellEditing {
 	border:1px solid rgb(102, 252, 255,0.6) !important;
    border-style:double !important;
    animation:editing 1s linear infinite;
}
.cellEditing:hover {
    animation: cellGlow 800ms ease-out infinite alternate, editing 1s linear infinite;;
}
@keyframes editing{

	50% {
		background-color:rgb(102, 252, 255,0.6);
	}
}

.dataCell.number{
}
.dataCell.state{
}
.dataCell.caseNumber{
}

.dataCell.filledDate{
	
}

.dataCell.city{
}

.dataCell.street{
}
.dataCell.plaintiff{
}
.dataCell.defendant{
}
.dataCell.aliases{
	white-space: nowrap;
}

.dataCell.contacts{
}
.dataCell.tags{
}

.dataHeader{
	background-color: #232f3e;

	color: white;
	    text-shadow: 2px 4px 3px rgb(0 0 0 / 30%);

}

.itemDeleteLarge{
color: #ff7070;
min-width:1.5em;
min-heiht:1.5em;
max-height:1.5em;
max-width:1.5em;
}

.itemDeleteLarge:hover{
color: red;
}
.contactWrapper{

}
.revertButton{
	font-size:1em;
	padding:0.5em;
	margin: 0.5em;
}
.editWrapper{
	width:100%;
}
.propertyAttributeWrapper{
	display:flex;
	align-items:center;
}
.flexEditWrapper{
	white-space:nowrap;
	display:flex;
	align-items:center;
	width:100%;
}
.changeWrapper{
	white-space:nowrap;
	display:flex;
	align-items:center;
}
.addWrapper{
	width:100%;
	text-align:center;
}
.textAreaFill{
	box-sizing:border-box;
	width:100%;
	text-align:inherit;
	border:none;
}
.border-none {
  border-collapse: collapse;
  border: none;
}

.border-none td {
  border: 1px solid #232f3e;
}

.border-none tr:first-child td {
  border-top: none;
}

.border-none tr:last-child td {
  border-bottom: none;
}

.border-none tr td:first-child {
  border-left: none;
}

.border-none tr td:last-child {
  border-right: none;
}