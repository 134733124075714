.contactsControlPanel{
    background-color: white;
    padding:1em;
    border-radius: 1em;
    margin-bottom:1em;
}

.contactControlInput{
    width:10em;
    text-align: center;
}