.watermelon {
    background-color: #E13071;
}

.sprout {
    background-color: #86B945;
}

.celestial {
    background-color: #29c4a7
}

.easter {
    background-color: #b870ff
}

.mango {
    background-color: #ff813d
}

.bumblebee{
    background-color: #ffc72e
}