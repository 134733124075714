.landingHero{
  flex-wrap: wrap;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.15);
}
.landingHeroChild{
  flex:1;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  min-width:350px;
  min-height:400px;
  background-size:cover;
}
.landingHeroHeading{
  font-size:3em;
  text-align: center;
  color: white;
  display:flex;
  align-items: center;
  justify-content: center;
  text-shadow: 4px 5px 4px rgba(0, 0, 0, 0.5);
}
.landingHeroText{
  font-size: 0.4em;

}
.landingHeader{
  width:100%;
  text-align: center;
}
.landingBlurb{
  text-align: center;
}
.landingHeroContent{
background-color: rgba(60, 93, 130,0.6);
width:100%;
padding:0.25em;
}
.landingHeroTitle{
  font-weight:bold;
}
.landingVideoWrapper{
  min-width:350px;
  flex:1;
  width:50%;
}
.landingHeroVideo{
  min-height:400px;
height:100%;
width:100%;
}
.landingButton{
  font-size:0.5em !important;
  margin-bottom:0.5em;
  text-shadow: none;
  border-radius: 0.5em!important;
}

.landingSection{
  display:flex;
  justify-content: center;
  align-items: center;
}
.featuresRow{
  display:flex;
  width:100%;
}
.featuresItem{
  color:grey;
  margin-bottom:0;
  flex:1;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap:nowrap;
  white-space: nowrap;
  border-bottom:3px solid transparent;
}
.featuresItem.active {
  color: #036ffc;
  border-bottom:3px solid #036ffc;
}
.featuresItem:hover {
}
.featuresItemText{
display:none;
}
.featuresItemIcon{
  width:5em;
  height:5em;
}

.featuresCarousel{
  background-color: #547396;
  border-radius: 0.5em;
  overflow: hidden;
}
.featuresCarouselSlide{
  color:white;
  display:flex;
  flex-wrap:wrap;
}
.featuresCarouselSlideChild{
  flex:1;
  display:flex;
  justify-content: center;
  align-items: center;
  min-width:350px;
  min-height:230px;
}
.featuresCarouselIcon{
  width:1.5em;
  height:1.5em;
}
.featuresBlurb{
display: flex;
flex-wrap:wrap;
justify-content: center;
flex-direction: column;
height:100%;
width:100%;
}
.featuresHeader{
  font-size:1.5em;
  text-align: center;
}
.featuresContent{
  align-items: flex-start;
  padding-top:0.5em;
}
.featuresList{
  display:flex;
  width:100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

}
.featuresList div{
  width:80%;
  flex-grow: 1;
  display:flex;
  justify-content: center;
  align-items: center;
}
.featuresList div span{
text-align: center;
}

@media (min-width: 700px) {
  .featuresItem{
    margin:0;
    font-size:1.3em;
    padding:0.25em 0.5em;
    color:gray;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap:nowrap;
    white-space: nowrap;
    background:none;
  }
   .featuresItem.active {
    border-bottom:3px solid #036ffc;
    }
    .featuresItem:hover {
    }
.featuresItemText{
    display:block;
  }
  .featuresItemIcon{
    width:1.5em;
    height:1.5em;
  }
    
}

.landingListIcon{
  color:grey;
  width:4em;
  height:4em;
}