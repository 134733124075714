.exportWrapper{
	display:flex;
	justify-content:center;
	align-items:center;
}
.exportPanelWrapper{
	border-radius:0.5em;
	border:1px solid black;
	display:flex;
	align-items:center;
	padding:0.5em;
	flex-grow:1;
	margin:0.5em;
	justify-content:space-between;
}

.exportPanel{
	display:flex;
flex-grow:1;
flex-wrap:nowrap;
}

.exportHeader{
		display:flex;
justify-content:center;
	align-items:center;
	padding:0.5em;
}
.exportContent{
		display:flex;
		justify-content:center;
	align-items:center;
	flex:1;
}
.exportHalf{
flex-basis:50%;
}

.exportFull{
flex-basis:100%;
}
