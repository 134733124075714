.listenerCard {
    border-radius: 1em;
}

.stateItem {
    border-radius: 1em;
    border: 1px solid black;
    margin: 0.25em;
}

.stateAdd {
    width: 6em;

    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    text-align: center;
}

.listenerControlButton {
    float: right;
}

.listenerHeader {
    background-color: #232f3e;
    color: white;
    height: 3em;
}

.listenerHeaderCell {
    min-width: 5em;
    display:flex;
    justify-content:center;
    align-items:center;
}

.lineListInput{
    font-size:0.8em;
    height:5em;
    width:100%;
    white-space:nowrap;
      -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}

.lineListInput::-webkit-scrollbar {
  display: none;
}


.exportTextAreaInput{
       font-size:0.8em;
    width:15em; 
    height:5em;
}
.exportListTypeInput{
    text-align:center;
    width:8em;
}

.exportTextContainer{
height:5em;
font-size:0.8em;
    overflow:scroll;
    padding-left:0.5em;
    padding-right:0.5em;
      -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}

.exportTextContainer::-webkit-scrollbar {
  display: none;
}


.exportFieldContainer{
    color: red;
}

