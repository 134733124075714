.toolTip {
  position: relative;
  display: inline-block;
  overflow:visible;
}


.toolTipGroup{
	visibility:hidden
}
.toolTipText {
  width: 8em;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -4em;
}
.toolTipArrow{
  width: 1em;
  height:1em;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 0.5em 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 0;
  bottom: 95%;
  	transform: rotate(45deg);
  left: 50%;
  margin-left: -0.5em;
}

.toolTipActive .toolTipGroup {
  visibility: visible;
  opacity:100%;
  animation: 1s 1s fadeOut ease-in forwards;
}

@keyframes fadeOut{
100%{
	opacity:0%;
}
}