.dashContainer{display: flex;
	flex: 1 1 auto;
	align-items:stretch;
	width:100%;
	min-height: calc(100vh - 3.5rem);
}
.dashMenu{
position: fixed;
height: 100%;
min-width:14em;
line-height: 1.5em;
background-color: #232f3e;
box-shadow: 4px 0 8px 0 rgba(0,0,0,0.2);
z-index:5;
}
.dashMenuWrapper{
min-width:14em;
}
.dashContent{
	background-color: rgb(241, 244, 246);
    scrollbar-gutter: stable both-edges;
	padding: 1em;
	width: 100%;
	height: 100%;
}
.dashContentWrapper{
	flex-grow:1;

}
.profile{
	padding: 0 1em;
}

.authWrapper{
	background-color: rgb(241, 244, 246);
	display:flex;
	min-height: calc(100vh - 3.5rem);
	align-items: center;
	justify-content:center;
}

.icon{
	width:1.5em;
	height:1.5em;
	margin-right: 0.5em;
	vertical-align: middle;
	padding: 0.25em;
	border-radius: 0.3em;
}

.profilePic{
		width:2em;
	height:2em;
}
