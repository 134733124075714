.dataHeaderCell {
    border: 1px solid #232f3e;
    text-align: center;
    padding: 0.5em;
}


.checkInput {
    width: 1em;
    height: 1em;
    display: inline;
    vertical-align: middle;
}

.checkLabel {
    vertical-align: middle;
}

.listInput {
    width: 7em;
    text-align: center;
    height: 2em;
}

.listInputLarge {
    width: 8em;
    text-align: center;
    height: 2em;
}

.integerInput {
    width: 3em;
    text-align: center;
    text-decoration: none;
    height: 2em;
}

.itemInput {
    user-select: none;
    padding: 0.25em;
}

.textInput {
    width: 100%;
}

.propertyInput{
    width:10em;
    text-align:center;
}

.itemDelete {
    color: #ff7070;
    width: 1.5em;
    height: 1.5em;

}

.itemDelete:hover {
    color: red;
}

.nosplit {
    white-space: nowrap;
}
.item{
    white-space:nowrap;
    padding:0.25em;
    display:flex;
    align-items: center;
    justify-content: center;
}
.itemTag{
    white-space:nowrap;
    display:inline-block;
    padding:0.5em;
    margin:0.25em;
        border-radius:2em;
    outline:1px solid black;
}
.itemRemovable{
    cursor:pointer;
}
.itemRemovable:hover {

  animation: blink 0.8s linear infinite;
}

@keyframes blink {
  50% {
    opacity: 0.3;
  }
}


.filterWrapper {
    text-align: center;
    flex: 1;
    white-space: nowrap;
    margin: 0.5em;
    display:flex;
    justify-content: center;
    align-items: center;
}

.based {
    flex-basis: 40%;
}

.right {
    float: right;
}

.filterCell {
    border: 1px solid #232f3e;
}

.filterContent {
    width: calc(100% - 5em);
    vertical-align: middle;
}

.filterFlex {
    display: flex;
    align-items:center;
}

.filterWrap {
    flex-wrap: wrap;
}

.changeGroup {
    display: flex;
    align-items: center;
    margin: 0.25em;
    padding: 0.25em;
    border: 1px solid black;
    border-radius: 1em;
}

.changeGroupHeader {
    color: blue;
}

.changeGroupEntry{
    color:fuchsia;
}

.modifyNewEntry {
border: 1px solid lime;
animation:newEntryFlash 1s linear infinite;
}

@keyframes newEntryFlash {
    0% {
    background-color: rgba(0,255,0,0.3);
    }

    50% {
    background-color: transparent;
    }

    100% {
    background-color: rgba(0,255,0,0.3);
    }
}

.modifyDanger {
border: 1px solid red;
animation:dangerFlash 1s linear infinite;
}

@keyframes dangerFlash {
    0% {
    background-color: rgba(255,0,0,0.3);
    }

    50% {
    background-color: transparent;
    }

    100% {
    background-color: rgba(255,0,0,0.3);
    }
}

.alertIcon {
    color: red;
}

.searchButton {}

.dataFilterHeader {
    background-color: #232f3e;
    color: white;
    padding: 0.5em;
    text-align: center;
}


.dataFilterHeaderCell {
    min-width: 6em;
    display:flex;
    justify-content:center;
    align-items:center;
}

.dataFilterRow {
    display: flex;
    width:100%;

}

.dataFilterWrapper {
    margin-bottom: 0.75em;
    border-radius: 1em;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border;
    background-color: white;
}


.iconHandle {
    width: 1em;
    height: 1em;
    position: relative;
    left: 0.5em;
}

.iconHandle:hover {
    color: lightgray;
}

.pageTurner {
    user-select: none;
}

.pageTurnerWrapper {
    width: 100%;
    text-align: right;
    margin: 1em 0;
}

.pageArrow {
    width: 2em;
    height: 2em;
    margin: -0.5em;
}

.iconButtonLarge {
    width: 2em;
    height: 2em;
}

.iconButtonMedium {
    width: 1.5em;
    height: 1.5em;
}

.iconButtonSmall {
    width: 1em;
    height: 1em;
}

.cleanInput {
    width: 3em;
}

.cleanInput::-webkit-outer-spin-button,
.cleanInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}