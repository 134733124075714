.planList{
}

.planLeft{
	text-align:left;
}


.planCard{
	border-radius:1em;
}
.planContent{
}

.planAddons{
	display:inline-block;
	border-radius:1em;
	overflow:hidden;
}

.planAddonsHeader{
	font-weight:bold;
	padding:0.5em 1em;
}
.planAddonsContent{
	padding:0.5em 1em;
	text-align:left;
}

.planActionButton{
	border-radius:1em;
	font-size:1.2em;
}
.addonSubText{
	font-size:0.8em;
}

.planControlButton{
	font-size:1.5em;
}
.planControlInfo{
	font-size:1.5em;
}
.planSubscribed{
	animation: glow 800ms ease-out infinite alternate;
	border:3px solid;
	border-color: #393;
	box-shadow: 0 0 5px rgba(0,255,0,.2), inset 0 0 5px rgba(0,255,0,.1), 0 2px 0 #000;
	outline: none;
}

@keyframes glow {
    0% {
		border-color: #393;
		box-shadow: 0 0 5px rgba(0,255,0,.2), inset 0 0 5px rgba(0,255,0,.1), 0 0 0 #000;
    }	
    100% {
		border-color: #6f6;
		box-shadow: 0 0 20px rgba(0,255,0,.6), inset 0 0 10px rgba(0,255,0,.4), 0 0 0 #000;
    }
}