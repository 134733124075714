.hoverOverlay{
position:relative;
height:10em;
width:100%;
}
.hoverContent{
	position:relative;
	width:100%
}
.hiddenOverlay{
width:100%;
height:100%;
position:absolute;
display:none;
}
.hoverOverlay:hover .hiddenOverlay{
	display:block;
}