.vCardHandle{
	position: relative;
	font-size:0.8em;
	display:flex;
	align-items: center;
	justify-content: space-between;
	background-color:#eee;
	padding:0.5em;
	border-radius:0.5em;
	border: 1px solid rgba(55,55,55,0.2);
	box-sizing:border-box;
	white-space:nowrap;
	text-align:center;
	width:100%;
}

.vCardHandle:hover{
	background-color:lightgray;
}

.vCardWrapper{
		border-radius:0.5em;
		box-shadow: 2px 0 8px 0 rgba(0,0,0,0.2);
        box-sizing: border;
        margin:0.5em;
        background-color:white;
}

.vCardIcon{
	width:1.5em;
	height:1.5em;
}
.vCardToggle{
font-size:1.3em;
}
.vCardPanel{
	min-width:20em;
	max-width:20em;
	white-space:normal;
	overflow-wrap:break-word;
	text-align:left;
	padding:0.5em;
}

.vCardGroup{
padding:0.5em;
}
.vCardGroupEdit{
padding:0.25em;
margin:0.25em;
border-radius:0.5em;
border: 1px solid rgba(55, 55, 5, 0.2);
width:100%;

}
.vCardGroupHeader{
}

.contactValue{
	margin: 0.25em 0;
	padding: 0.25em;
	width:100%;
}

.contactValueEdit{
	margin: 0.25em 0;
	padding: 0.25em;
	width:100%;
}
.contactNotes{
	font-style: italic;
	color: #2E3033;
}

.contactInfo{

}